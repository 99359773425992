<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Se<span>lux</span> riolering</a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <img ngSrc="assets/images/selux_groot.png" style="width: 50px;height:50px" alt="logo" height="1000" width="1000">
  <p *ngIf="isNewUrlFirstWeek" style="margin:0 0 0 40px !important;height:20px">Nieuwe URL: <b>selux-riolering.com</b></p>

</div>
<div class="header-container">
  <nb-actions style='margin-right: 100px;' size="small">
    <nb-action class="control-item">
      <div style="position: relative; display: inline-block;">
        <button nbButton status="basic" style="margin-left: 40px !important;background-color: #d6e6ff" (click)="openUpdateWindow()">
          Updates
        </button>
        <nb-badge *ngIf="isRecentNewUpdate" text="nieuw" status="success" position="top right" style="position: absolute; top: -10px; right: -10px;"></nb-badge>
      </div>

    </nb-action>
  </nb-actions>

  <nb-actions size="small">
    <nb-action class="control-item">
      <button nbButton outline status="danger" (click)="onLogoutClick()">
        afmelden
      </button>
    </nb-action>

  </nb-actions>
</div>
